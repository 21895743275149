import React, {useEffect, useState} from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import "./devisForm.scss";
import AutoTextArea from "./AutoTextArea";
import { getCookie } from "../cookies";

//const url = process.env.GATSBY_API_URL // this should be defined
const devisFormFunction = `${ process.env.GATSBY_FRONT_END_URL }/.netlify/functions/sendDevisForm`;

const getGAIds = () => {
// Try to retrieve Google Analytics information
    return new Promise((resolve, reject) => {
        try {
            window.gtag('get', 'G-HCRRMMGNDF', 'client_id', (client_id) => {
                if(client_id){
                    resolve({ trackingId: 'G-HCRRMMGNDF', clientId: client_id });
                }
            })
        } catch(e) {
            // eslint-disable-next-line no-console
            console.error('Error retrieving GA tracking');
            resolve();
        }
    });
};

const DevisFormLogic = () => {
    const { register, handleSubmit, setValue, getValues, watch, errors, control } = useForm();
    const [ response, setResponse ] = useState(null);

    useEffect(() => {
        if(response?.result){
            console.log("result ok, send event.");
            if(window.gtag) {
                console.log("-- send event confirmation --");
                
                console.log({
                    event_category: 'contact_event',
                    event_label: 'confirmation_demande_devis_action',
                    leadId: response.result.leadId,
                    source: response.result.source,
                    medium: response.result.medium,
                    user_id: response.result.email,
                    zip_code: response.result.zip_code,
                    problem_type: response.result.problem_type,
                });

                window.gtag('event', 'confirmation_demande_devis_action', {
                    event_category: 'contact_event',
                    event_label: 'confirmation_demande_devis_action',
                    leadId: response.result.leadId,
                    source: response.result.source,
                    medium: response.result.medium,
                    user_id: response.result.email,
                    zip_code: response.result.zip_code,
                    problem_type: response.result.problem_type,
                });
            }
        }
    }, [response]);

    const checkBox = () => {
        setValue("rgpd", !getValues('rgpd'));
    }

    const onSubmit = async data => {
        if(response?.message === 'Envoi en cours...'){
            return;
        }

        setResponse({
            message: 'Envoi en cours...',
            status: true,
        });

        const arrival_url = getCookie('arrival_url');
        const referer = getCookie('referer');
        const current_url = window.location.href;
        const gaIds = await getGAIds();

        data = {
            ...data,
            arrival_url,
            referer,
            current_url,
            gaTrackingId: gaIds?.trackingId,
            gaClientId: gaIds?.clientId
        };

        const sendForm = await fetch(`${ devisFormFunction }`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        const res = await sendForm.json();

        if(res){
            setResponse(res);
        }
    }

    const departements = [
        { value: "manche", label: "Manche" },
        { value: "calvados", label: "Calvados" },
        { value: "orne", label: "Orne" },
        { value: "autre_departement", label: "Autre département" },
    ];

    return (
        <div>
            <form onSubmit={ handleSubmit( onSubmit ) }>

                <div className="input">
                    <div>
                        <label>Nom : </label>
                        <input name="nom" placeholder="Nom*" ref={ register({ required: true }) } />
                        {errors.nom && <span className="err">Votre nom est requis.</span>}
                    </div>
                </div>

                <div className="input">
                    <div>
                        <label>Prénom : </label>
                        <input name="prenom" placeholder="Prénom*" ref={ register({ required: true }) } />
                        {errors.prenom && <span className="err">Votre prénom est requis.</span>}
                    </div>
                </div>

                <div className="input">
                    <div>
                        <label>Téléphone : </label>
                        <input name="telephone" placeholder="0678463723*" type="tel" id="phone" ref={ register({ required: true }) } />
                        {errors.telephone && <span className="err">Votre téléphone est requis.</span>}
                    </div>
                </div>

                <div className="input">
                    <div>
                        <label>Email : </label>
                        <input name="email" type="email" placeholder="example@gmail.com*" size="30"
                               ref={ register({ required: true }) }
                        />
                        {errors.email && <span className="err">Votre email est requis.</span>}
                    </div>
                </div>

                <div className="input">
                    <label>Département : </label>
                    <Controller
                        name="departement"
                        control={ control }
                        placeholder="Votre département :"
                        rules={{ required: true }}
                        classNamePrefix="react-select"
                        options={ departements }
                        defaultValue={ null }
                        as={ Select }
                    />
                    {errors.departement && <span className="err">Choisissez votre département.</span>}
                </div>

                <div className="input">
                    <label>Votre problème : </label>
                    <Controller
                        name="service"
                        control={ control }
                        placeholder="Votre problème concerne :"
                        defaultValue={ null }
                        classNamePrefix="react-select"
                        options={[
                            { value: "deratisation", label: "Dératisation" },
                            { value: "desinsectisation", label: "Désinsectisation" },
                            { value: "desinfections", label: "Désinfections" },
                            { value: "extermination_cafards", label: "Extermination de cafards" },
                            { value: "je_ne_sais_pas", label: "Je ne sais pas" },
                        ]}
                        rules={{ required: true }}
                        as={ Select }
                    />
                    {errors.thematique && <span className="err">Choisissez un type de problème.</span>}
                </div>

                <div className="input textarea">
                    <label>Décrivez votre problème : </label>
                    <div>
                        <Controller
                            render={(props) => (
                                <AutoTextArea
                                    value={ props.value }
                                    onChange={ props.onChange }

                                />
                            )}
                            name="demande"
                            defaultValue={""}
                            control={ control }
                            rules={{ required: true }}
                        />

                        {errors.demande && <span className="err">Vous nous avez rien dit sur votre problème !</span>}
                    </div>
                </div>

                <div className="input checkbox">
                    <div className="container" onClick={ checkBox }>
                        <input type="checkbox" name="rgpd"
                               ref={ register({ required: true }) } />
                        <span className="checkmark" />
                    </div>
                    <span className="rgpd">En soumettant ce formulaire, j’accepte que les informations saisies soient<br />
exploitées dans le cadre strict de ma demande*</span>
                    {errors.rgpd && <span className="err">Vous devez accepter ces conditions pour envoyer votre demande.</span>}
                </div>

                {response &&
                <div className={`message ${!response.status ? 'err' : ''}`}>
                    { response.message }
                </div>
                }

                <div className="input submit">
                    <input type="submit" className="call-to-action" value="Envoyer votre demande" />
                </div>

            </form>
        </div>
    );
}

const DevisForm = () => {

    return(
        <div className="devis-form">
            <h2>Votre demande de devis</h2>
            <p>Obtenez notre devis sans engagement maintenant
                <br />en remplissant ce formulaire.</p>
            <div className="inner">
                <DevisFormLogic />
            </div>
        </div>
    )
}

export default DevisForm;